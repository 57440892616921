<template>
  <b-modal
    ref="password-modal"
    no-close-on-backdrop
    hide-footer
    title="เปลี่ยนรหัสผ่าน"
    ok-variant="success"
    ok-title="เปลี่ยนรหัสผ่าน"
    cancel-title="ยกเลิก"
    footer-class="justify-content-between"
    @show="resetModal"
    @hidden="resetModal"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >

      <b-form-group
        label="รหัสผ่านเดิม"
        label-for="input-old-password"
      >
        <b-form-input
          id="input-old-password"
          v-model="password.old_password"
          type="password"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="รหัสผ่านใหม่"
        label-for="input-new-password"
      >
        <b-form-input
          id="input-new-password"
          v-model="password.new_password"
          type="password"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="ยืนยันรหัสผ่าน"
        label-for="input-confirm-password"
      >
        <b-form-input
          id="input-confirm-password"
          v-model="password.confirm_password"
          type="password"
          required
        ></b-form-input>
      </b-form-group>

      <b-button type="submit" variant="danger" block pill :disabled="isProcess">{{isProcess ? 'กำลังบันทึกรหัสผ่าน...' : 'เปลี่ยนรหัสผ่าน'}}</b-button>

    </form>
  </b-modal>
</template>
<script>
import AuthService from '@/services/authService'
import Auth from '@/helpers/auth'

import Swal from 'sweetalert2'

export default {
  name: 'ChangePasswordModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isProcess: false,
      password: {
        old_password: '',
        new_password: '',
        confirm_password: ''
      }
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    }
  },
  methods: {
    resetModal() {
      this.isProcess = false
      this.password = {
        old_password: '',
        new_password: '',
        confirm_password: ''
      }
    },
    showModal() {
      this.$refs['password-modal'].show()
    },
    hideModal() {
      this.$refs['password-modal'].hide()
    },
    handleSubmit() {

      if(this.password.new_password !== this.password.confirm_password) {
        Swal.fire({
          text: 'ยืนยันรหัสผ่านไม่ถูกต้อง',
          icon: 'error',
          confirmButtonText: 'OK'
        }).then((res)=>{
          this.password.confirm_password = ''
        })
        return
      }

      const self = this
      this.isProcess = true
      AuthService.changePassword(this.password)
      .then((response)=>{
        console.log(response)
        if(response.status === 'success') {
          self.$nextTick(() => {
            self.$refs['password-modal'].hide()
          })
          return Swal.fire({
            title: 'สำเร็จ!',
            text: 'เปลี่ยนรหัสผ่านเสร็จเรียบร้อย กรุณาเข้าสู่ระบบใหม่อีกครั้ง',
            icon: 'success',
            confirmButtonText: 'OK'
          }).then((result)=>{
            Auth.logout()
          })
        }else{
          throw new Error(response?.data)
        }
      })
      .catch((e)=>{
        console.log('eee', e)
        Swal.fire({
          title: 'ผิดพลาด!',
          text: (e?.data || e?.message) || 'เปลี่ยนรหัสผ่านไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isProcess = false
      })
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close')
    })
  }
}
</script>
