<template>
  <CSidebar 
    fixed 
    :minimize="minimize"
    :show="show"
    dropdownMode="closeInactive"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    
    <!--
    <CSidebarBrand class="d-md-down-none" to="/">
      <CIcon 
        class="c-sidebar-brand-full" 
        name="logo" 
        size="custom-size" 
        :height="35" 
        viewBox="0 0 556 134"
      />
      <CIcon 
        class="c-sidebar-brand-minimized" 
        name="logo" 
        size="custom-size" 
        :height="35" 
        viewBox="0 0 110 134"
      />
    </CSidebarBrand>
    -->

    <CRenderFunction flat :content-to-render="nav"/>
    
    <!--<CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />-->
  </CSidebar>
</template>

<script>
import AppConfig from '@/configs/app.config.js'
import {AdminNav, ManagerNav, BranchNav} from './_nav'

export default {
  name: 'TheSidebar',
  computed: {
    show () {
      return this.$store.state.sidebarShow 
    },
    minimize () {
      return this.$store.state.sidebarMinimize 
    },
    nav() {
      const LoggedInData = JSON.parse(localStorage.getItem(AppConfig.key.LoggedIn))
      const Role = LoggedInData?.role

      if(Role==='Admin'){
        return this.navFilter(AdminNav)
      }else
      if(Role==='Manager'){
        return this.navFilter(ManagerNav)
      }else
      if(Role==='Branch'){
        return this.navFilter(BranchNav)
      }else{
        return []
      }
    }
  },
  methods: {
    navFilter(nav) {
      const LoggedInData = JSON.parse(localStorage.getItem(AppConfig.key.LoggedIn))
      const userRole = LoggedInData?.userRole

      let setNav = JSON.parse(JSON.stringify(nav))

      setNav = setNav.filter((level1)=>{
        if(!level1?.userRole)
          return true

        return level1?.userRole === userRole
      })

      setNav = setNav.map((nav)=>{

        if(nav?._children) {
          nav._children = nav._children.filter((children)=>{
            if(!children?.userRole)
              return true

            return children?.userRole === userRole
          })

          nav._children = nav._children.map((children)=>{

            if(children?.items) {
              children.items = children.items.filter((item)=>{
                if(!item?.userRole)
                  return true

                return item?.userRole === userRole
              })
            }

            return children
          })
        }

        return nav
      })

      return setNav
    }
  }
}
</script>

<style lang="scss">
.c-sidebar {
  // background-color: #10462E;
}

.c-sidebar .c-sidebar-nav-link, .c-sidebar .c-sidebar-nav-dropdown-toggle {
  // color: #fff;
}

.c-sidebar > ul a.c-active {
  // color: lightgreen !important;
}
</style>
