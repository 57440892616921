<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <img
            src="/img/avatars/1.jpg"
            class="c-avatar-img"
          />
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>Account</strong>
    </CDropdownHeader>
    <CDropdownItem @click="isChangeProfile = !isChangeProfile">
      <CIcon name="cil-user" /> แก้ไขข้อมูลส่วนตัว
    </CDropdownItem>
    <CDropdownItem @click="isChangePassword = !isChangePassword">
      <CIcon name="cil-settings" /> เปลี่ยนรหัสผ่าน
    </CDropdownItem>
    <CDropdownItem @click="logout">
      <CIcon name="cil-lock-locked" /> Logout
    </CDropdownItem>
    <ChangePasswordModal :is-show="isChangePassword" @close="isChangePassword = false" />
    <EditProfileModal :is-show="isChangeProfile" @close="isChangeProfile = false" />
  </CDropdown>
</template>

<script>
import Auth from '@/helpers/auth'

import ChangePasswordModal from '@/views/components/ChangePasswordModal'
import EditProfileModal from '@/views/components/EditProfileModal'

export default {
  name: 'TheHeaderDropdownAccnt',
  components: {
    ChangePasswordModal,
    EditProfileModal
  },
  data () {
    return { 
      isChangePassword: false,
      isChangeProfile: false
    }
  },
  computed: {
    UserInfo() {
      return this.$store.state.userProfile
    }
  },
  methods: {
    logout() {
      console.log('logout!')
      Auth.logout()
    }
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>
