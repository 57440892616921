export const AdminNav = [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavDropdown',
        name: 'รายงาน',
        route: '/admin',
        fontIcon: 'fas fa-chart-pie',
        items: [
          {
            _name: 'CSidebarNavItem',
            name: 'สรุป กำไร/ขาดทุน',
            fontIcon: 'fas fa-angle-double-right',
            to: '/admin/net-profit'
          },
          {
            _name: 'CSidebarNavItem',
            name: 'รายงาน กำไร/ขาดทุน',
            fontIcon: 'fas fa-angle-double-right',
            to: '/admin/balance'
          },
          {
            _name: 'CSidebarNavItem',
            name: 'รายการลูกค้าค้าง',
            fontIcon: 'fas fa-angle-double-right',
            to: '/admin/accrual'
          },
          {
            name: 'ยอดประจำวัน',
            fontIcon: 'fas fa-angle-double-right',
            to: '/admin/report/daily'
          },
          {
            name: 'สรุปยอดรวม',
            fontIcon: 'fas fa-angle-double-right',
            to: '/admin/report/summary'
          },
          {
            name: 'ยอดเว็บไซต์/เอเย่นต์',
            fontIcon: 'fas fa-angle-double-right',
            to: '/admin/report/agent'
          },
          {
            name: 'ยอดแบ่งตามประเภทหวย',
            fontIcon: 'fas fa-angle-double-right',
            to: '/admin/report/market'
          },
          {
            name: 'ยอดขาด/เกิน',
            fontIcon: 'fas fa-angle-double-right',
            to: '/admin/report/other'
          }
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'ตั้งค่า',
        route: '/admin/setting',
        fontIcon: 'fas fa-cogs',
        items: [
          {
            name: 'ผู้จัดการ',
            fontIcon: 'fas fa-angle-double-right',
            to: '/admin/setting/manager'
          },
          {
            name: 'ผู้ช่วย',
            fontIcon: 'fas fa-angle-double-right',
            to: '/admin/setting/assistant',
            userRole: 'Owner'
          },
          {
            name: 'เว็บไซต์',
            fontIcon: 'fas fa-angle-double-right',
            to: '/admin/setting/website'
          },
          {
            name: 'แบ่งกลุ่มหวย',
            fontIcon: 'fas fa-angle-double-right',
            to: '/admin/setting/market-group'
          },
          {
            name: 'ประเภทหวย',
            fontIcon: 'fas fa-angle-double-right',
            to: '/admin/setting/market'
          },
          {
            name: 'สาเหตุขาด/เกิน',
            fontIcon: 'fas fa-angle-double-right',
            to: '/admin/setting/item'
          }
        ]
      }
    ]
  }
]

export const ManagerNav = [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavDropdown',
        name: 'รายงาน',
        route: '/manager',
        fontIcon: 'fas fa-chart-pie',
        items: [
          {
            _name: 'CSidebarNavItem',
            name: 'รายการลูกค้าค้าง',
            fontIcon: 'fas fa-angle-double-right',
            to: '/manager/accrual'
          },
          {
            name: 'ยอดประจำวัน',
            fontIcon: 'fas fa-angle-double-right',
            to: '/manager/report/daily'
          },
          {
            name: 'สรุปยอดรวม',
            fontIcon: 'fas fa-angle-double-right',
            to: '/manager/report/summary'
          },
          {
            name: 'ยอดเว็บไซต์/เอเย่นต์',
            fontIcon: 'fas fa-angle-double-right',
            to: '/manager/report/agent'
          },
          {
            name: 'ยอดแบ่งตามประเภทหวย',
            fontIcon: 'fas fa-angle-double-right',
            to: '/manager/report/market'
          },
          {
            name: 'ยอดขาด/เกิน',
            fontIcon: 'fas fa-angle-double-right',
            to: '/manager/report/other'
          }
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'ตั้งค่า',
        route: '/manager/setting',
        fontIcon: 'fas fa-cogs',
        items: [
          {
            name: 'รายชื่อทีม',
            fontIcon: 'fas fa-angle-double-right',
            to: '/manager/setting/branch',
          }
        ]
      }
    ]
  }
]

export const BranchNav = [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'บันทึก',
        fontIcon: 'far fa-edit',
        to: '/branch/daily-record'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'รายการลูกค้าค้าง',
        fontIcon: 'far fa-handshake',
        to: '/branch/accrual'
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'รายงาน',
        route: '/branch/report',
        fontIcon: 'fas fa-chart-area',
        items: [
          {
            name: 'สรุปรายวัน',
            fontIcon: 'fas fa-angle-double-right',
            to: '/branch/report/daily'
          },
          {
            name: 'แบ่งตามเว็บไซต์/เอเย่นต์',
            fontIcon: 'fas fa-angle-double-right',
            to: '/branch/report/agent'
          },
          {
            name: 'แบ่งตามประเภทหวย',
            fontIcon: 'fas fa-angle-double-right',
            to: '/branch/report/market'
          },
          {
            name: 'ยอดขาด/เกิน',
            fontIcon: 'fas fa-angle-double-right',
            to: '/branch/report/other'
          }
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'ตั้งค่า',
        route: '/branch/setting',
        fontIcon: 'fas fa-cogs',
        items: [
          {
            name: 'รหัสเอเย่นต์',
            fontIcon: 'fas fa-angle-double-right',
            to: '/branch/setting/agent',
            // userRole: 'Owner'
          },
          // {
          //   name: 'ผู้จัดการ',
          //   fontIcon: 'fas fa-angle-double-right',
          //   to: '/branch/setting/manager',
          //   userRole: 'Owner'
          // },
          {
            name: 'พนักงาน',
            fontIcon: 'fas fa-angle-double-right',
            to: '/branch/setting/member',
            userRole: 'Owner'
          }
        ]
      }
    ]
  }
]
